import posthog from 'posthog-js';

document.addEventListener('DOMContentLoaded', () => {
  const apiKey = document.querySelector('meta[name="posthog"]')?.getAttribute('content');

  if (apiKey)
    posthog.init(apiKey, {
      api_host: 'https://app.posthog.com',
      autocapture: {
        event_allowlist: ['click'], // DOM events from this list ['click', 'change', 'submit']
        element_allowlist: ['button', 'a'] // DOM elements from this list ['a', 'button', 'form', 'input', 'select', 'textarea', 'label']
      },
      disable_session_recording: true
    });
  else {
    // Dummy PostHog client
    posthog.init = function () {};
    posthog.identify = function (arg) { console.log(`PostHog identify: ${arg}`); };
    posthog.capture = function () {};
  }

  window.posthog = posthog;
});
